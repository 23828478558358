<template>
  <div>
    <div class="mt-4 mx-5 mb-3">
      <b-form-input
          v-model="name"
          :placeholder="namePlaceholder"
          @update="setName"
          autofocus
          debounce="500"
          @keyup.enter="$emit('enter-pressed');"
      >
      </b-form-input>
    </div>
    <div class="mx-5 mb-4">
      <b-form-input
          v-model="description"
          :placeholder="descriptionPlaceholder"
          @update="setDescription"
          debounce="500"
          @keyup.enter="$emit('enter-pressed');"
      >
      </b-form-input>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalChooseNameAndDescription',
  props: ['nameProp', 'descriptionProp', 'namePlaceholder', 'descriptionPlaceholder'],
  data: () => ({
    name: null,
    description: null,
  }),
  created() {
    this.name = this.nameProp;
    this.description = this.descriptionProp;
  },
  methods: {
    setName(value) {
      this.$emit('update-fields', 'name', value);
    },
    setDescription(value) {
      this.$emit('update-fields', 'description', value);
    },
  },
};
</script>
