var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-4 mx-5 mb-3" },
      [
        _c("b-form-input", {
          attrs: {
            placeholder: _vm.namePlaceholder,
            autofocus: "",
            debounce: "500"
          },
          on: {
            update: _vm.setName,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.$emit("enter-pressed")
            }
          },
          model: {
            value: _vm.name,
            callback: function($$v) {
              _vm.name = $$v
            },
            expression: "name"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mx-5 mb-4" },
      [
        _c("b-form-input", {
          attrs: { placeholder: _vm.descriptionPlaceholder, debounce: "500" },
          on: {
            update: _vm.setDescription,
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.$emit("enter-pressed")
            }
          },
          model: {
            value: _vm.description,
            callback: function($$v) {
              _vm.description = $$v
            },
            expression: "description"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }